import englishMessages from 'ra-language-english';
import { CAUCASUS_TYPES, targetGroupAttributeTypes } from '../../config/constants';
import { accountCodeLabel, managerLabel } from '../../pages/users/components/UserLabelConsts';

const isCaucasus = CAUCASUS_TYPES.includes(process.env.REACT_APP_APP_TYPE);

const overviewTitles = {
  HUNGARY: 'TM overview',
  GEORGIA: 'TMR overview',
  ARMENIA: 'TMR overview',
  AZERBAIJAN: 'TMR overview',
  IRELAND: 'BA overview',
};

const messages = {
  root: {
    i18n: {
      changeLanguage: 'Change Language',
    },
    listToolBar: {
      import: 'IMPORT',
    },
    statistics: {
      national: 'National Statistics',
      region: 'Region',
      allContacts: 'All contacts',
      summary: 'Summary',
      available: 'Available',
      tmOverview: overviewTitles[process.env.REACT_APP_APP_TYPE] || 'BA overview',
      'Logged in': 'Logged in',
      'Deleted users': 'Deleted users',
      'Live contacts': 'Live contacts',
      'Aged contacts': 'Aged contacts',
      'Cold contacts': 'Cold contacts',
      'Waiting contacts': 'Waiting contacts',
      'Waiting moderation': 'Waiting moderation',
      'Waiting in moderation': 'Waiting in moderation',
      'Waiting for moderation': 'Waiting for moderation',
      'Waiting for registration': 'Waiting for registration',
      'Blocked contacts': 'Blocked contacts',
      Blocked: 'Blocked',
      'Blocked by Admin': 'Blocked by Admin',
      'Active users': 'Active users',
      Deactivated: 'Deactivated',
      'Downloaded contacts': 'Downloaded contacts',
      'Contacts not on App': 'Contacts not on App',
      region_entry: '%{name} region',
      region_summary: 'Summary: %{name}',
      all_period: 'All Period',
      last_four_weeks: 'Last 4 weeks',
      before_last_four_weeks: '4+ weeks',

      Database: 'Database',
      'Total contacts': 'Total contacts',
      'Under Moderation': 'Under Moderation',
      'Total downloads': 'Total downloads',

      Assigned: 'Assigned',
      Remaining: 'Remaining',
      Active: 'Active',
      'Waiting Moderation': 'Waiting Moderation',
      'Waiting Remove': 'Waiting Remove',
      TBC: 'TBC',
      Limited: 'Limited',
      'With Sellers': 'With Sellers',
      'Without Sellers': 'Without Sellers',

      'General activity': 'General activity',
      Challenge: 'Challenge',
      challenge_description: 'users completed tasks',
      Chance: 'Chance',
      chance_description: 'number of bets',
      'Daily question': 'Question of the day',
      daily_question_description: 'times played',
      'Head to head quizzes': 'Head to head quizzes',
      h2h_quiz_description: 'bonus + non bonus',
      'Solo quizzes': 'Solo quizzes',
      solo_quiz_description: 'bonus + non bonus',
      'Single mode': 'Single mode',
      single_mode_description: 'the number of the 1st level games were finished',
      'Event mode': 'Event mode',
      event_mode_description: 'the number of unique games played',
      Survey: 'Survey',
      survey_description: 'the number of unique surveys played',

      'Brand activity': 'Brand activity',
      Articles: 'Articles',
      articles_description: 'times read',
      Games: 'Games',
      games_description: 'times played',
      'Special quizzes': 'Special quizzes',
      special_quiz_description: 'times played',
      'Multibrand quiz': 'Multibrand quiz',
      multibrand_quiz_description: 'times played',

      'Brand knowledge - Special Quizzes': 'Brand knowledge - Special Quizzes',
      brand_knowledge_description: 'average % of product knowledge',
      'Brand knowledge - General Quizzes': 'Brand knowledge - General Quizzes',
      'Brand knowledge - Quick Tips': 'Brand knowledge - Quick Tips',

      Costs: 'Costs',
      'Credits earned': `${isCaucasus ? 'Coins' : 'Points'} earned`,
      credits_earned_description: `number of ${isCaucasus ? 'credits' : 'points'} earned by all users`,
      'Currency earned': `${isCaucasus ? 'Dollars' : 'Euro'} earned`,
      currency_earned_description: `amount of ${isCaucasus ? 'dollars' : 'euro'} earned by all users`,
      'Credits spent': `${isCaucasus ? 'Coins' : 'Points'} spent`,
      credits_spent_description: `amount of ${isCaucasus ? 'credits' : 'points'} spent by all users in Bonus Shop`,
      Orders: 'Orders',
      orders_description: 'orders made by all users',
      'Orders cost': `Orders costs, ${isCaucasus ? '$' : '€'}`,
      orders_costs_description: 'value of orders made by all users',
      'Physical orders': 'Physical orders',
      physical_orders_description: 'orders made by all users',
      'Digital orders': 'Digital orders',
      digital_orders_description: 'orders made by all users',

      'Promo codes': 'Promo codes',
      'Promo codes activated': 'Promo codes activated',
      promocode_activated_description: 'number of activated promocodes',
      'Promo codes credits earned': `Promo ${isCaucasus ? 'credits' : 'points'} earned`,
      promocode_credits_earned_description: `amount of ${
        isCaucasus ? 'credits' : 'points'
      } earned with activated promo codes`,

      'All outlets to which you are assigned': 'All outlets to which you are assigned',
      'The total number of users at your outlets': 'The total number of users at your outlets',
      'Users who need your moderation to access the App': 'Users who need your moderation to access the App',
      'Users who have been active in the last 30 days': 'Users who have been active in the last 30 days',

      'The total number of users at the outlet': 'The total number of users at the outlet',
      'The number of users who have logged in to the App at the outlet':
        'The number of users who have logged in to the App at the outlet',
      'Users who have been active in the last 30 days at the outlet':
        'Users who have been active in the last 30 days at the outlet',
      "Users who have't been active in the last 30 days  at the outlet":
        "Users who have't been active in the last 30 days  at the outlet",
    },
    uploadImage: 'Upload image',
    contracted_statuses: {
      contracted: 'Contracted',
      not_contracted: 'Not-Contracted',
    },
    rulesForm: {
      formTypeTogglerLabel: 'Rule attributes',
      togglerOptions: {
        [targetGroupAttributeTypes.POSITION]: { label: 'Position', placeholder: 'Positions' },
        [targetGroupAttributeTypes.TIER]: { label: 'Tier', placeholder: 'Tiers' },
        [targetGroupAttributeTypes.ACCOUNT_CODE]: { label: 'Account Code', placeholder: 'Account code' },
        [targetGroupAttributeTypes.CITY]: { label: 'City', placeholder: 'Cities' },
        [targetGroupAttributeTypes.MANAGER]: { label: 'Manager', placeholder: 'Managers' },
        [targetGroupAttributeTypes.USER_ID]: { label: 'Users', placeholder: '' },
        [targetGroupAttributeTypes.ROLE]: { label: 'Roles', placeholder: 'Roles' },
        [targetGroupAttributeTypes.OUTLET_CONTRACTED]: { label: 'Outlet Contracted', placeholder: 'Contract status' },
        [targetGroupAttributeTypes.OUTLET_CONTRACT_TYPE]: {
          label: 'Outlet Contract Type',
          placeholder: 'Contract type',
        },
        [targetGroupAttributeTypes.USER_VERIFICATION]: { label: 'Verified', placeholder: 'Verification status' },
        [targetGroupAttributeTypes.REVERSED]: { label: 'All except selected group', placeholder: 'Target group' },
        [targetGroupAttributeTypes.REGISTRATION]: { label: 'Registered users', placeholder: 'Registered users' },
      },
    },
  },
  resources: {
    'admin/outlets-users': {
      name: 'Outlets',
    },
    'admin/outlets': {
      name: 'Outlets',
    },
    'admin/users': {
      name: 'Users',
      fields: {
        id: 'User ID',
        name: 'User Name',
        surname: 'User Surname',
        username: 'Profile Name',
        middle_name: 'Middle Name',
        email: 'E-Mail',
        date_of_birth: 'Date of Birth',
        phone: 'Phone',
        user_phone: 'User Phone',
        status: 'Status',
        ploom: 'Ploom',
        status_custom: 'Status',
        position_id: 'Position',
        manager_id: managerLabel,
        city_id: 'City',
        legal_entity_id: 'Legal Entity',
        region_id: 'Region',
        street: 'Address',
        signature: 'Signature',
        created_at: 'Date created',
        contracted: 'Contracted',
        contract_type: 'Contact type',
        'manager.fullName': `${managerLabel} Name`,
        'manager.username': 'Manager username',
        'manager.region.name': 'Region',
        'esignature.status': 'E-Sign',
        is_active_in_last_month: 'Activity',
        last_transaction_date: 'Last transaction date',
        email_last_modified_date: 'Email last modified date',
        first_login_date: 'Download Date',
        app_version: 'App Version',
        'address.outlet_code': 'Outlet Code',
        traffic_code: accountCodeLabel,
        address: 'Address',
        outlet_address_id: 'Outlet address',
        photo: 'Photo',
        seller_code: 'Seller code',
        'verification.verified': 'Verified',
        'verification.reset_verification': 'Reset verification',
      },
    },
    'admin/statistics': {
      name: 'Statistics',
      fields: {
        start_date: 'Start date',
        end_date: 'Choose end date',
      },
    },
    'admin/target-groups': {
      name: 'Target groups',
      fields: {
        name: 'Name',
        rules: 'Rules',
        id: 'ID',
      },
    },
    'admin/cpa-program': {
      name: 'CPA',
    },
    'admin/trade-program': {
      name: 'Trade program',
    },
  },
  subMenusLabels: {
    manage: 'Manage',
    figureHunting: 'Figure Hunting',
    reports: 'Reports',
    outlets: 'Outlets',
    problems: 'Problems',
    content: 'Content',
    knowledgeBank: 'Knowledge bank',
    winWinBonusShop: 'Bonus Shop',
    bonusShop: 'Bonus Shop',
    badgeSystem: 'Badge System',
    dailyGames: 'Daily Games',
    scheduleList: 'Schedule List',
  },
  reports: {
    error: {
      download: 'Error downloading the report.',
    },
  },
};

export default { ...englishMessages, ...messages };
