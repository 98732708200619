import { MessageOutlined } from '@material-ui/icons';
import { APP_TYPES, roles } from '../../../config/constants';
import ScheduleList from '../ScheduleList';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN, roles.BA, roles.JTI_TEAM, roles.RSM, roles.USER],
  label: 'Messages',
  subMenuName: 'scheduleList',
};

export default {
  list: ScheduleList,
  icon: MessageOutlined,
  name: 'admin/schedule/type/message',
  options,
};
