import React from 'react';
import { BooleanInput, maxLength, NumberInput, required, TextInput } from 'react-admin';

import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { mainScreenItemComponentTypes, s3FilePaths } from '../../../../config/constants';
import { isIreland } from '../../../../helpers/appTypeHelper';
import withCardWrapper from '../../hocs/withCardWrapper';

const getFieldName = (member, name) => {
  if (!member) {
    return name;
  }

  return `${member}.${name}`;
};

const getReferenceInput = (member, type) => {
  let fieldLabel = '';
  let fieldSourceName;
  switch (type) {
    case mainScreenItemComponentTypes.kb_article:
      fieldLabel = 'KB Article ID';
      fieldSourceName = 'knowledge_bank_article_id';
      break;
    case mainScreenItemComponentTypes.special_quiz:
      fieldLabel = 'Special Quiz ID';
      fieldSourceName = 'pack_id';
      break;
    case mainScreenItemComponentTypes.kb_game:
      fieldLabel = 'KB Game ID';
      fieldSourceName = 'knowledge_bank_article_id';
      break;
    case mainScreenItemComponentTypes.survey:
      fieldLabel = 'KB Survey ID';
      fieldSourceName = 'knowledge_bank_article_id';
      break;
    case mainScreenItemComponentTypes.multibrand_quiz:
      fieldLabel = 'Multibrand Quiz ID';
      fieldSourceName = 'pack_id';
      break;
    default:
      throw new Error(`Unsupported type for Item component(TypeId = ${type}).`);
  }

  return <NumberInput label={fieldLabel} source={getFieldName(member, fieldSourceName)} validate={required()} />;
};

const BoxComponent = ({ member, type }) => (
  <>
    <TextInput
      label="Name"
      source={getFieldName(member, 'name')}
      validate={[required(), isIreland() && maxLength(19)]}
    />
    <ImageInput
      label="Image"
      imagePath={s3FilePaths.allUploads}
      imageInputSource={getFieldName(member, 'image')}
      validate={required()}
    />
    {getReferenceInput(member, type)}
    {isIreland() && (
      <TextInput
        label="Description"
        source={getFieldName(member, 'description')}
        validate={[required(), maxLength(52)]}
      />
    )}
    {!isIreland() && <NumberInput label="Priority" source={getFieldName(member, 'priority')} />}
    <BooleanInput label="Visible" source={getFieldName(member, 'visible')} />
    <CustomDateInput label="Visible from" source={getFieldName(member, 'visible_from')} />
    <CustomDateInput label="Visible to" source={getFieldName(member, 'visible_to')} />
  </>
);

export default withCardWrapper(BoxComponent);
