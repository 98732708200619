import React from 'react';
import { Create, NumberInput, required, SimpleForm } from 'react-admin';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../config/constants';

const FavoriteTileCreate = (props) => (
  <Create undoable={false} {...props}>
    <SimpleForm redirect="list">
      <NumberInput label="Position" source="position" min={1} validate={required()} />
      <ImageInput label="Image" imageInputSource="image" imagePath={s3FilePaths.favoriteTiles} />
    </SimpleForm>
  </Create>
);

export default FavoriteTileCreate;
