import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    '& > li:last-child': {
      borderBottom: 'none',
    },
  },
  formTypeTogglerSection: {
    width: 256,
  },
  line: {
    display: 'flex',
    listStyleType: 'none',
    [theme.breakpoints.down('xs')]: { display: 'block' },
    '&.fade-enter': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '&.fade-enter-active': {
      opacity: 1,
      transform: 'translateX(0)',
      transition: 'all 500ms ease-in',
    },
    '&.fade-exit': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '&.fade-exit-active': {
      opacity: 1,
      transform: 'translateX(0)',
      transition: 'all 500ms ease-in',
    },
  },
  index: {
    width: '3em',
    paddingTop: '1em',
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
  form: {
    flex: 2,
    marginTop: 25,
  },
  action: {
    paddingTop: '0.3em',
    '& .button-add': {
      marginTop: 4,
    },
    '& .button-remove': {
      marginTop: 36,
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

const FormIterator = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [nextId, setNextId] = useState(
    // eslint-disable-next-line no-nested-ternary
    props.fields.length ? props.fields.length : props.defaultValue ? props.defaultValue.length : 0,
  );
  const [ids] = useState(nextId > 0 ? Array.from(Array(nextId).keys()) : []);
  const [nextFormType, setNextFormType] = useState('');

  const removeField = (index) => () => {
    const { fields } = props;
    const removedField = fields.value[index];

    ids.splice(index, 1);
    fields.remove(index);

    if (props.removeField) {
      props.removeField(removedField);
    }
  };

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determine if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  const disableRemoveField = (record, disableRemove) => {
    if (typeof disableRemove === 'boolean') {
      return disableRemove;
    }
    return disableRemove && disableRemove(record);
  };

  const addField = () => {
    const { fields } = props;

    ids.push(nextId);
    setNextId(nextId + 1);
    props.pushNewField({ fields, nextFormType });
  };

  const renderForm = ({ index, member, record }) => {
    const { fields, resource, quizType } = props;
    const field = fields.value[index];

    return props.renderForm({ field, member, resource, record, quizType, index });
  };

  const {
    fields,
    meta: { error, submitFailed },
    record,
    source,
    disableAdd,
    disableRemove,
    maxLength,
  } = props;
  const records = get(record, source);

  return fields ? (
    <ul className={classes.root}>
      {submitFailed && error && <FormHelperText error>Form is invalid</FormHelperText>}
      {!disableAdd && !(maxLength && fields.length >= maxLength) && (
        <li className={classes.line}>
          <section>
            <FormControl className={classes.formTypeTogglerSection}>
              <InputLabel htmlFor="question-type-picker" variant="filled" margin="dense">
                {props.formTypeTogglerLabel}
              </InputLabel>
              <Select
                value={nextFormType}
                onChange={(e) => {
                  setNextFormType(e.target.value);
                }}
                inputProps={{ id: 'question-type-picker' }}
                variant="filled"
                margin="dense"
                autoWidth={false}
              >
                {props.formTypeTogglerOptions.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </section>
          <span className={classes.action}>
            <Button
              className={`button-add button-add-${source}`}
              size="small"
              disabled={!nextFormType}
              onClick={addField}
            >
              <AddIcon className={classes.leftIcon} />
              {translate('ra.action.add')}
            </Button>
          </span>
        </li>
      )}
      <TransitionGroup>
        {fields.map((member, index) => (
          <CSSTransition key={ids[index]} timeout={500} classNames="fade">
            <li className={classes.line}>
              <section className={classes.form}>{renderForm({ index, member, record })}</section>
              {!disableRemoveField((records && records[index]) || {}, disableRemove) && (
                <span className={classes.action}>
                  <Button
                    className={`button-remove button-remove-${source}-${index}`}
                    size="small"
                    onClick={removeField(index)}
                  >
                    <CloseIcon className={classes.leftIcon} />
                    {translate('ra.action.remove')}
                  </Button>
                </span>
              )}
            </li>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </ul>
  ) : null;
};

FormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
};

FormIterator.propTypes = {
  defaultValue: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default FormIterator;
