import intersection from 'lodash/intersection';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Filter, ReferenceInput, SelectInput, TextInput, useTranslate } from 'react-admin';
import CityAutocompleteInput from '../../../commonComponents/CityAutocompleteInput';
import ManagerAutocompleteInput from '../../../commonComponents/ManagerAutocompleteInput';
import PhoneInput from '../../../commonComponents/PhoneInput';
import {
  APP_TYPES,
  appTypesStatuses,
  booleanFilterChoices,
  CAUCASUS_TYPES,
  customStatuses,
  eSignatureStatuses,
  outletContractedStatuses,
  outletContractTypes,
  passportStatuses,
  roles,
  azeUserIDCardStatusOptions,
  geoUserIDCardStatusOptions,
} from '../../../config/constants';
import { isAzerbaijan, isGeorgia } from '../../../helpers/appTypeHelper';
import { CustomDateInput } from '../../../commonComponents/CustomDateInput';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const UsersFilter = forwardRef((props, ref) => {
  const { permissions, onFilterChange, ...rest } = props;
  const translate = useTranslate();
  const outletContractedStatusesL10n = outletContractedStatuses.map((o) => ({
    ...o,
    name: translate(o.name),
  }));

  const idCardStatusesChoices = isGeorgia() ? geoUserIDCardStatusOptions : azeUserIDCardStatusOptions;

  const prevFilterValues = usePrevious(rest.filterValues);
  useEffect(() => {
    if (
      JSON.stringify(rest.filterValues) !== JSON.stringify(prevFilterValues) &&
      JSON.stringify(rest.filterValues) !== JSON.stringify({})
    ) {
      onFilterChange();
    }
    // eslint-disable-next-line
  }, [rest.filterValues]);

  useImperativeHandle(ref, () => ({
    resetFilter: () => rest.setFilters({}),
  }));

  return (
    <Filter {...rest}>
      {permissions && intersection(permissions, [roles.ADMIN, roles.JTI_TEAM, roles.BA]).length > 0 && (
        <TextInput source="id" alwaysOn />
      )}
      <TextInput source="name" alwaysOn />
      <TextInput source="surname" alwaysOn />
      {ENV_APP_TYPE !== APP_TYPES.HUNGARY && <TextInput source="traffic_code" alwaysOn />}
      <PhoneInput isRequired={false} alwaysOn />
      {![APP_TYPES.IRELAND, APP_TYPES.HUNGARY].includes(ENV_APP_TYPE) && (
        <SelectInput source="status" optionText="name" choices={appTypesStatuses[ENV_APP_TYPE]} alwaysOn />
      )}
      {ENV_APP_TYPE === APP_TYPES.BELARUS && (
        <SelectInput source="passport_status" choices={passportStatuses} alwaysOn />
      )}
      {[APP_TYPES.IRELAND, APP_TYPES.HUNGARY].includes(ENV_APP_TYPE) && (
        <SelectInput source="custom_status" optionText="name" choices={customStatuses} alwaysOn />
      )}
      {ENV_APP_TYPE === APP_TYPES.IRELAND && (
        <SelectInput source="esign_status" optionText="name" choices={eSignatureStatuses} alwaysOn />
      )}
      <ReferenceInput source="position_id" reference="services/positions" alwaysOn>
        <SelectInput optionText="name" />
      </ReferenceInput>
      {permissions && intersection(permissions, [roles.ADMIN, roles.JTI_TEAM]).length > 0 && (
        <ManagerAutocompleteInput alwaysOn />
      )}
      <SelectInput source="is_active_in_last_month" choices={booleanFilterChoices} alwaysOn />
      {ENV_APP_TYPE === APP_TYPES.HUNGARY && <CityAutocompleteInput source="city_id" alwaysOn />}
      {ENV_APP_TYPE !== APP_TYPES.BELARUS && (
        <ReferenceInput source="region_id" reference="admin/services/regions" alwaysOn>
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
      {ENV_APP_TYPE === APP_TYPES.HUNGARY && (
        <ReferenceInput source="legal_entity_id" reference="admin/legal-entities" perPage={10000} alwaysOn>
          <SelectInput source="name" optionText={(le) => `(${le.id}) ${le.name}`} />
        </ReferenceInput>
      )}
      {ENV_APP_TYPE === APP_TYPES.IRELAND && (
        <ReferenceInput source="tier_id" reference="admin/tiers" alwaysOn>
          <SelectInput source="name" />
        </ReferenceInput>
      )}
      {ENV_APP_TYPE === APP_TYPES.HUNGARY && <TextInput source="street" alwaysOn />}
      {ENV_APP_TYPE === APP_TYPES.HUNGARY && (
        <SelectInput source="contracted" optionText="name" choices={outletContractedStatusesL10n} alwaysOn />
      )}
      {ENV_APP_TYPE === APP_TYPES.HUNGARY && (
        <SelectInput source="contract_type" optionText="name" choices={outletContractTypes} alwaysOn />
      )}
      {CAUCASUS_TYPES.includes(ENV_APP_TYPE) && <TextInput label="Legal ID" source="legal_id" alwaysOn />}

      {(isAzerbaijan() || isGeorgia()) && (
        <SelectInput label="ID Card Status" source="id_card_status" choices={idCardStatusesChoices} alwaysOn />
      )}
      <CustomDateInput label="Email modified from" source="email_last_modified_date_from" start alwaysOn />
      <CustomDateInput label="Email modified to" source="email_last_modified_date_to" end alwaysOn />
      <ReferenceInput source="badge_id" reference="admin/badge-system" alwaysOn>
        <SelectInput source="badge_id" optionText={(le) => `${le.display_name}`} />
      </ReferenceInput>
      <CustomDateInput label="Last transaction until " source="last_transaction_until" alwaysOn />
    </Filter>
  );
});

export default UsersFilter;
