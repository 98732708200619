import React from 'react';
import { Create, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin';
import TargetGroupInput from '../../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths, statuses } from '../../../../config/constants';
import ConditionalColorInput from './ConditionalColorInput';
import PresentCreateToolbar from './PresentCreateToolbar';

const PresentCreate = (props) => (
  <Create title="Create Present" {...props}>
    <SimpleForm redirect="list" toolbar={<PresentCreateToolbar />}>
      <TextInput label="Name" source="name" validate={required()} />
      <TextInput label="Description" source="description" validate={required()} />
      <TextInput label="Price" source="price" validate={required()} />
      <TextInput label="Promo price" source="promo_price" />
      <TextInput label="Nominal price" source="nominal_price" />
      <NumberInput label="Priority" source="priority" />
      <TargetGroupInput />
      <ReferenceInput label="Category" source="category_id" reference="admin/bonus-shop/categories/physical">
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
      <ReferenceInput label="Type" source="type" reference="admin/bonus-shop/presents/types">
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
      <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} validate={required()} />
      <NumberInput label="Count" source="count" validate={required()} />
      <ConditionalColorInput />
      <ImageInput imageInputSource="photo" validate={required()} imagePath={s3FilePaths.bonusShop} />
    </SimpleForm>
  </Create>
);

export default PresentCreate;
