import React from 'react';
import { BooleanInput, Edit, required, TextInput } from 'react-admin';

import { ColorInput } from 'react-admin-color-input';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ThemeSelector from '../../../commonComponents/ThemeSelector';
import ScreenForm from './ScreenForm';

const ScreenEdit = (props) => (
  <Edit title="Edit screen" undoable={false} {...props}>
    <ScreenForm redirect="list">
      <TextInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" validate={required()} />
      <TextInput label="Description" source="description" validate={required()} />
      <TargetGroupInput />
      <BooleanInput label="Active" source="active" />
      <ThemeSelector />
      <TextInput source={'navigation_title'} label={'Menu title'} />
      <ColorInput label="Unread items border color" source="unread_items_border_color" />
      <ColorInput label="Add new favorite button color" source="add_new_favorite_color" />
    </ScreenForm>
  </Edit>
);

export default ScreenEdit;
