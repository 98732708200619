import React from 'react';
import { Datagrid, List, NumberField, TextField } from 'react-admin';

const FavoriteTileList = (props) => (
  <List
    title="Favorite Tiles"
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'position', order: 'ASC' }}
    style={{ width: '70%' }}
    {...props}
  >
    <Datagrid rowClick="edit" size={'medium'}>
      <TextField label="ID" source="id" />
      <NumberField label="Position" source="position" />
    </Datagrid>
  </List>
);

export default FavoriteTileList;
