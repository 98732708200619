import React from 'react';

import CustomArrayInput from '../../../../commonComponents/CustomArrayInput';
import FormIterator from '../../../../core/FormIterator';
import { mainScreenItemComponentTypeToggleOptions } from '../../../../config/constants';
import ItemComponent from './ItemComponent';
import { isIreland } from '../../../../helpers/appTypeHelper';

const pushNewField = ({ fields, nextFormType }) => {
  fields.push({ type: { id: nextFormType } });
};

const renderForm = ({ field, member }) => <ItemComponent member={member} type={field.type.id} />;

const ItemComponentForm = React.memo(({ member }) => (
  <CustomArrayInput source={`${member}.item_components`} label="Item components" defaultValue={[]}>
    {
      <FormIterator
        formTypeTogglerLabel="Item component type"
        formTypeTogglerOptions={mainScreenItemComponentTypeToggleOptions}
        pushNewField={pushNewField}
        renderForm={renderForm}
        maxLength={isIreland() ? 100 : Infinity}
      />
    }
  </CustomArrayInput>
));

export default ItemComponentForm;
