import React, { useEffect, useState } from 'react';
import {
  Create,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import RewardInput from '../../../../commonComponents/RewardInput';
import TargetGroupInput from '../../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';

import {
  APP_TYPES,
  kbCategoryTypes,
  knowledgeBankCategorySections,
  productStatusSelectStatuses,
  s3FilePaths,
  statuses,
} from '../../../../config/constants';
import CategorySelectInput from './CategorySelectInput';
import { isHungary, isIreland } from '../../../../helpers/appTypeHelper';

const getSectionName = (section_in_category) =>
  knowledgeBankCategorySections.find((section) => section_in_category === section.id).name;

const getCategoriesWithSectionName = (categories) =>
  categories.map((category) => {
    const section_name = getSectionName(category.section);
    const category_name = `(${section_name}) ${category.name}`;
    return {
      id: category.id,
      name: category_name,
    };
  });

export const CustomSelectInput = ({ categoriesList, setCategoriesList, ...props }) => {
  const { choices: categories, source } = props;
  let categories_with_section_name = [];

  useEffect(() => {
    !categoriesList && setCategoriesList(categories);
  }, [categories, setCategoriesList, categoriesList]);

  if (categories) {
    categories.sort((a, b) => a.section - b.section);
    categories_with_section_name = getCategoriesWithSectionName(categories);
  }
  return (
    <>
      <SelectInput choices={categories_with_section_name} source={source} />
    </>
  );
};

const ArticleCreate = (props) => {
  const [categoriesList, setCategoriesList] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [isSurvey, setIsSurvey] = useState(false);
  const rewardInputFields = [
    { inputLabel: 'URL', inputType: 'string', radioLabel: 'URL', source: 'url' },
    {
      inputLabel: 'Multibrand or Special Quiz ID',
      inputType: 'number',
      radioLabel: 'Multibrand or Special Quiz ID',
      source: 'special_quiz_id',
    },
  ];
  const sendPushNotification =
    selectedCategory &&
    (process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND || process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY) &&
    selectedCategory.send_push_notification;

  return (
    <Create title="Create article" {...props}>
      <SimpleForm redirect="list" inititalValues={{ push: false, message: '' }}>
        <FormDataConsumer>
          {({ formData }) => {
            const { category_id } = formData;
            const selectedCategory = category_id
              ? categoriesList.find((category) => category.id === category_id)
              : null;
            setSelectedCategory(selectedCategory);
            setIsSurvey(selectedCategory && selectedCategory.type === kbCategoryTypes.Survey);
            return null;
          }}
        </FormDataConsumer>
        <ReferenceInput label="Category" source="category_id" reference="admin/knowledge-bank/categories" perPage={100}>
          {isHungary() ? (
            <CustomSelectInput
              categoriesList={categoriesList}
              setCategoriesList={setCategoriesList}
              validate={required()}
            />
          ) : (
            <CategorySelectInput
              optionText="name"
              categoriesList={categoriesList}
              setCategoriesList={setCategoriesList}
              validate={required()}
            />
          )}
        </ReferenceInput>
        <SelectInput source="status" optionText="name" choices={statuses.slice(0, 2)} validate={required()} />
        {isIreland() && (
          <BooleanInput
            style={{ width: '300px' }}
            label="Included in the Progress bar"
            source="progress_bar_enable"
            defaultValue={true}
          />
        )}
        <TextInput label="Article name" source="name" validate={required()} />
        {selectedCategory &&
          // eslint-disable-next-line no-nested-ternary
          (selectedCategory.show_quiz ? (
            <RewardInput formType="edit" inputFields={rewardInputFields} />
          ) : isSurvey ? (
            <ReferenceInput label="Survey" source="survey_id" reference="admin/surveys">
              <SelectInput optionText="name" />
            </ReferenceInput>
          ) : (
            <TextInput label="URL" source="url" />
          ))}
        {selectedCategory && selectedCategory.show_minutes_to_read && (
          <NumberInput label="Minutes to read" source="minutes_to_read" />
        )}
        {selectedCategory && selectedCategory.show_retail_price && (
          <TextInput label="Retail price" source="retail_price" />
        )}
        {selectedCategory && (selectedCategory.show_brand || selectedCategory.show_choose_brand) && (
          <ReferenceInput label="Brands" source="brand_id" reference="admin/knowledge-bank/brands">
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
        )}
        {selectedCategory && <CustomDateInput label="Choose publication date" source="published_at" />}
        {selectedCategory && <TargetGroupInput />}
        <NumberInput label="Priority" source="priority" />
        {!isSurvey && <NumberInput label="Count of bonus reads" source="count" validate={required()} />}
        <NumberInput
          label={selectedCategory && !selectedCategory.show_quiz ? 'Coins' : 'Coins (not for special quizzes)'}
          source="coins"
          validate={required()}
        />
        {!isSurvey && <NumberInput label="Points of non-bonus reads" source="points" validate={required()} />}
        {isHungary() && <SelectInput source="product_status" optionText="name" choices={productStatusSelectStatuses} />}
        <ImageInput
          label={isHungary() ? 'Preview Image' : 'Chat Preview Image'}
          imagePath={s3FilePaths.articles}
          imageInputSource="preview_image"
        />
        {sendPushNotification && (
          <>
            <BooleanInput label="Push notification" source="push" />
            <FormDataConsumer>
              {({ formData }) => formData && formData.push && <TextInput source="message" />}
            </FormDataConsumer>
          </>
        )}
        {(!isHungary() || !isIreland()) && (
          <ArrayInput label="Reward coefficients" source="reward_coefficients">
            <SimpleFormIterator>
              <NumberInput label="Coefficient" source="coefficient" validate={required()} />
              <CustomDateInput label="Start date" source="start_date" validate={required()} start />
              <CustomDateInput label="End date" source="end_date" end />
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </SimpleForm>
    </Create>
  );
};

export default ArticleCreate;
