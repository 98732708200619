import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';

const MainScreenModuleSectionTypeList = (props) => (
  <List
    title="Main Screen Module Component Section Types"
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'position', order: 'ASC' }}
    style={{ width: '70%' }}
    {...props}
  >
    <Datagrid size={'medium'} expand={false}>
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
    </Datagrid>
  </List>
);

export default MainScreenModuleSectionTypeList;
