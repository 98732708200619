import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  NumberInput,
  required,
} from 'react-admin';
import ProductCreateToolbar from './ProductCreateToolbar';

const ProductCreate = (props) => (
  <Create title="Add Product" {...props}>
    <SimpleForm redirect="list" toolbar={<ProductCreateToolbar />}>
      <TextInput label="Description" source="description" validate={required()} />
      <NumberInput label="Price" source="price" validate={required()} />
      <NumberInput label="Promo price" source="promo_price" />
      <TextInput label="Nominal price" source="nominal_price" validate={required()} />
      <ReferenceInput label="Provider" source="provider_id" reference="admin/bonus-shop/providers">
        <SelectInput validate={required()} />
      </ReferenceInput>
      <ReferenceInput label="Type" source="type" reference="admin/bonus-shop/presents/types">
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
      <BooleanInput label="Active" source="active" />
    </SimpleForm>
  </Create>
);

export default ProductCreate;
