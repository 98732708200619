import { ReferenceInput, SelectInput } from 'react-admin';
import React from 'react';

function SectionTypesSelector({ ...props }) {
  return (
    <ReferenceInput
      label="Section type"
      source={props.source || 'section_type'}
      perPage={5000}
      reference="admin/main-screen-module-component-section-types"
      allowEmpty
      emptyValue={null}
      {...props}
    >
      <SelectInput optionValue={'name'} optionText={(sectionType) => sectionType.name} />
    </ReferenceInput>
  );
}

export default SectionTypesSelector;
