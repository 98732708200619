import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { APP_TYPES, roles } from '../../config/constants';
import FavoriteTileCreate from './components/FavoriteTileCreate';
import FavoriteTileEdit from './components/FavoriteTileEdit';
import FavoriteTileList from './components/FavoriteTileList';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Favorite tiles',
  subMenuName: 'manage',
};

export default {
  create: FavoriteTileCreate,
  list: FavoriteTileList,
  edit: FavoriteTileEdit,
  icon: FavoriteBorder,
  name: 'admin/favorite-tiles',
  options,
};
