import MomentUtils from '@date-io/moment';
import momentTZ from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldTitle, useInput, useTranslate } from 'ra-core';
import { InputHelperText } from 'ra-ui-materialui';
import { MuiPickersUtilsProvider, DateTimePicker as DatePicker } from '@material-ui/pickers';

import { timeZones } from '../config/constants';

const appTimeZone = timeZones[process.env.REACT_APP_APP_TYPE];
const formatDateForView = 'DD.MM.yyyy HH:mm';
const formatDateForRequest = 'yyyy-MM-DD HH:mm';

const sanitizeRestProps = ({
  allowEmpty,
  alwaysOn,
  basePath,
  component,
  defaultValue,
  format,
  formClassName,
  initialValue,
  initializeForm,
  input,
  isRequired,
  label,
  limitChoicesToValue,
  locale,
  meta,
  options,
  optionText,
  optionValue,
  parse,
  record,
  resource,
  source,
  textAlign,
  translate,
  translateChoice,
  labelTime,
  ...rest
}) => rest;

export const CustomDateTimeInput = ({
  format,
  label,
  dateFormat,
  options,
  source,
  resource,
  margin,
  onChange,
  onFocus,
  parse,
  validate,
  variant,
  defaultValue,
  providerOptions: { utils, locale },
  pickerVariant,
  start,
  end,
  ...rest
}) => {
  const translate = useTranslate();
  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onChange,
    onFocus,
    resource,
    source,
    validate,
    ...rest,
  });
  const [selectedDate, handleDateChange] = useState(null);

  useEffect(() => {
    if (!input.value) {
      return;
    }

    if (input.value && (start || end)) {
      handleDateChange(momentTZ(input.value).tz(appTimeZone));
      return;
    }

    if (input.value) {
      handleDateChange(momentTZ(input.value));
    }
  }, [input.value]);

  const handleChange = useCallback((value) => {
    if (value === null) {
      handleDateChange(value);
      input.onChange(value);
      return;
    }

    let requestDate = momentTZ(value).format(formatDateForRequest);

    if (end) {
      requestDate = momentTZ(value).tz(appTimeZone).endOf('day').toISOString();
    }

    if (start) {
      requestDate = momentTZ(value).tz(appTimeZone).startOf('day').toISOString();
    }

    handleDateChange(value);
    input.onChange(requestDate);
  }, []);

  const LabelComponent = (
    <FieldTitle
      label={label || translate(`resources.${resource}.fields.${source}`)}
      source={source}
      resource={resource}
      isRequired={isRequired}
    />
  );

  const helperTextComponent = <InputHelperText touched={touched} error={error} />;

  return (
    <MuiPickersUtilsProvider utils={utils} locale={locale}>
      <DatePicker
        id={id}
        format={dateFormat}
        error={!!(touched && error)}
        value={selectedDate}
        ampm={false}
        onChange={handleChange}
        clearLabel={translate('ra.action.clear_input_value')}
        cancelLabel={translate('ra.action.cancel')}
        label={LabelComponent}
        helperText={helperTextComponent}
        inputVariant={variant}
        variant={pickerVariant}
        margin={margin}
        {...options}
        {...sanitizeRestProps(rest)}
        clearable
      />
    </MuiPickersUtilsProvider>
  );
};

CustomDateTimeInput.defaultProps = {
  dateFormat: formatDateForView,
  isRequired: false,
  label: '',
  meta: { touched: false, error: false },
  options: {},
  resource: '',
  source: '',
  labelTime: '',
  className: '',
  providerOptions: {
    utils: MomentUtils,
    locale: undefined,
  },
  margin: 'dense',
  variant: 'filled',
  pickerVariant: 'dialog',
};

CustomDateTimeInput.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  labelTime: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  start: PropTypes.bool,
  end: PropTypes.bool,
};
