import Category from '@material-ui/icons/Category';
import { APP_TYPES, roles } from '../../config/constants';
import MainScreenModuleSectionTypeList from './components/MainScreenModuleSectionTypeList';
import MainScreenModuleSectionTypeEdit from './components/MainScreenModuleSectionTypeEdit';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN],
  label: 'Main Screen Module Component Section Types',
  subMenuName: 'manage',
};

export default {
  create: null,
  list: MainScreenModuleSectionTypeList,
  edit: MainScreenModuleSectionTypeEdit,
  icon: Category,
  name: 'admin/main-screen-module-component-section-types',
  options,
};
