import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import { Create, SelectInput, SimpleForm, TextInput, maxLength, required } from 'react-admin';
import { CustomDateTimeInput } from '../../../commonComponents/CustomDateTimeInput';
import TargetGroupInput from '../../../commonComponents/TargetGroupInput';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import {
  popupCharacterLimits,
  popupNavigationButtonTypesSelect,
  popupThemes,
  s3FilePaths,
} from '../../../config/constants';
import PopupNavigationSelect from './PopupNavigationSelect';

const PopupCreate = (props) => (
  <Create undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Title" source="title" validate={[required(), maxLength(popupCharacterLimits.TITLE)]} />
      <RichTextInput label="Message" source="message" validate={required()} fullWidth={false} />
      <ImageInput label="Picture" imageInputSource="image" imagePath={s3FilePaths.popupImage} />
      <ImageInput label="Logo" imageInputSource="logo_url" imagePath={s3FilePaths.popupLogo} />
      <SelectInput label="Popup theme" source="theme" choices={popupThemes} validate={required()} />
      <TextInput
        label="Popup Button Text"
        source="button_text"
        validate={[required(), maxLength(popupCharacterLimits.BUTTON_TEXT)]}
      />
      <SelectInput
        label="Button type"
        source="button_type"
        choices={popupNavigationButtonTypesSelect}
        validate={required()}
      />
      <PopupNavigationSelect />
      <TargetGroupInput />
      <CustomDateTimeInput label="Date from" source="date_from" validate={required()} />
      <CustomDateTimeInput label="Date to" source="date_to" validate={required()} />
    </SimpleForm>
  </Create>
);

export default PopupCreate;
