import { useState } from 'react';
import { SaveButton, Toolbar, useNotify, useRedirect } from 'react-admin';
import { useForm } from 'react-final-form';
import { Dialog, DialogContent, DialogTitle, Button, DialogActions } from '@material-ui/core';
import httpClient from '../../../../core/httpClient';
import { serverApiUrl } from '../../../../config/constants';
import { CustomDateTimeInput } from '../../../../commonComponents/CustomDateTimeInput';

const CouponEditToolbar = () => {
  const form = useForm();
  const [isOpen, setOpenStatus] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setOpenStatus(true);
  };

  const handleCloseModal = () => {
    setOpenStatus(false);
  };

  const handleSchedule = () => {
    const values = form.getState()?.values;

    const url = `${serverApiUrl}/admin/schedule/coupons`;
    const body = JSON.stringify({
      ...values,
      _type: 'update',
      schedule_date: values.schedule_date ? new Date(values.schedule_date).toISOString() : null,
    });
    const options = { method: 'POST', body };

    httpClient(url, options)
      .then(() => {
        notify('Coupon update has been scheduled', 'info');
        redirect('/admin/bonus-shop/coupons');
      })
      .catch(() => notify('Error: Coupon update failed', 'warning'));
  };

  return (
    <Toolbar style={{ display: 'flex', gap: '1rem' }}>
      <SaveButton handleSubmitWithRedirect={form.submit} label="Save" disabled={!form.getState().valid} />

      <Button
        onClick={handleOpenModal}
        type="button"
        variant="contained"
        color="default"
        disabled={!form.getState().valid}
      >
        Schedule
      </Button>

      <Dialog open={isOpen} onClick={(e) => e.stopPropagation()}>
        <DialogTitle>Schedule Coupon Update</DialogTitle>

        <DialogContent dividers>
          <CustomDateTimeInput label="Select the schedule date" source="schedule_date" />
        </DialogContent>
        <DialogActions>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button label="ra.action.cancel" variant="text" onClick={handleCloseModal}>
              Cancel
            </Button>

            <Button
              type="button"
              variant="text"
              color="primary"
              label="Schedule"
              disabled={!form.getFieldState('schedule_date')?.value}
              onClick={() => handleSchedule()}
            >
              Schedule
            </Button>
          </DialogActions>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
};

export default CouponEditToolbar;
