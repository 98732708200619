import React from 'react';
import { Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';

const MainScreenModuleSectionTypeEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <NumberInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" disabled />
    </SimpleForm>
  </Edit>
);

export default MainScreenModuleSectionTypeEdit;
