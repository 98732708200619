import { createBrowserHistory } from 'history';
import React from 'react';
import { Admin, Resource } from 'react-admin';

import { allRoles, APP_TYPES, roles, serverApiUrl } from './config/constants';
import authProvider from './core/authProvider';
import CustomLayout from './core/customLayout/Layout';
import customRoutes from './core/customRoutes';
import customTheme from './core/customTheme';
import httpClient from './core/httpClient';
import serverAPIProvider from './core/serverAPIProvider';
import i18nProvider from './i18n/i18nProvider';

import isBaOrRsm from './helpers/isBaOrRsm';
import administrators from './pages/administrators';
import badgeSystem from './pages/badgeSystem';
import bonusShop from './pages/bonusShop';
import challengeAttachments from './pages/challenges/attachments';
import chats from './pages/chats';
import contentSubMenu from './pages/content';
import cpa from './pages/cpaProgram';
import dailyGameSubmenus from './pages/dailyGames';
import scheduleList from './pages/schedule';
import figureHuntingSubMenu from './pages/figureHunting';
import knowledgeBank from './pages/knowledgeBank';
import lepCodes from './pages/lep/codes';
import logs from './pages/logs';
import loyaltyProgramAttachments from './pages/loyaltyPrograms/attachments';
import manageSubMenu from './pages/manage';
import managers from './pages/managers';
import mysteryShopperAttachments from './pages/mysteryShopper/attachments';
import outlets from './pages/outlets';
import outletsAndUsers from './pages/outletsAndUsers';
import problems from './pages/problems';
import reports from './pages/reports';
import salesSimulatorScenarios from './pages/salesSimulator/scenarios';
import statistics from './pages/statistics';
import tickets from './pages/tickets';
import tradeProgram from './pages/tradeProgram';
import users from './pages/users';
import winWinBonusShop from './pages/winWinBonusShop';
import ProviderProductsEdit from './pages/winWinBonusShop/providers/components/ProviderProductsEdit';

const history = createBrowserHistory();
const dataProvider = serverAPIProvider(serverApiUrl, httpClient);
const renderSubMenu = (resourceSettings) => <Resource key={resourceSettings.name} {...resourceSettings} />;

const App = () => {
  const { referrer } = document;

  if (!(referrer === process.env.REACT_APP_ALLOWED_REFERRER)) {
    window.location.href = process.env.REACT_APP_ALLOWED_REFERRER;
    return null;
  }

  return (
    <Admin
      layout={CustomLayout}
      customRoutes={customRoutes}
      history={history}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      theme={customTheme}
    >
      <Resource {...users} />
      {!isBaOrRsm ? <Resource {...badgeSystem} /> : <Resource name="empty" />}
      <Resource {...statistics} />
      {!isBaOrRsm ? <Resource {...managers} /> : <Resource name="empty" />}
      {!isBaOrRsm ? <Resource {...administrators} /> : <Resource name="empty" />}
      {!isBaOrRsm ? <Resource {...loyaltyProgramAttachments} /> : <Resource name="empty" />}
      {!isBaOrRsm ? <Resource {...challengeAttachments} /> : <Resource name="empty" />}
      <Resource {...mysteryShopperAttachments} />
      <Resource {...lepCodes} />
      <Resource {...salesSimulatorScenarios} />
      {!isBaOrRsm ? <Resource {...outletsAndUsers} /> : <Resource name="empty" />}
      {outlets.map((resource) => (!isBaOrRsm ? renderSubMenu(resource) : <Resource name="empty" />))}
      {manageSubMenu.filter((resource) => !isBaOrRsm || resource.name === 'admin/settings').map(renderSubMenu)}
      {figureHuntingSubMenu.map((resource) => (!isBaOrRsm ? renderSubMenu(resource) : <Resource name="empty" />))}
      {dailyGameSubmenus.map((resource) => (!isBaOrRsm ? renderSubMenu(resource) : <Resource name="empty" />))}
      {scheduleList.map(renderSubMenu)}
      <Resource
        {...{
          ...logs,
          options: {
            ...logs.options,
            allowedRoles: [roles.JTI_TEAM, roles.ADMIN],
            subMenuName: null,
            appTypes: [APP_TYPES.BELARUS],
          },
        }}
      />
      {contentSubMenu.map((resource) => (!isBaOrRsm ? renderSubMenu(resource) : <Resource name="empty" />))}
      {knowledgeBank.map((resource) => (!isBaOrRsm ? renderSubMenu(resource) : <Resource name="empty" />))}
      {winWinBonusShop.map(renderSubMenu)}
      {bonusShop.map((resource) => (!isBaOrRsm ? renderSubMenu(resource) : <Resource name="empty" />))}
      {problems.map(renderSubMenu)}
      <Resource {...tickets} />
      <Resource {...chats} />
      {reports.map(renderSubMenu)}
      <Resource {...cpa} />
      <Resource {...tradeProgram} />
      <Resource
        options={{ allowedRoles: [roles.ADMIN, roles.JTI_TEAM] }}
        name="admin/products"
        edit={ProviderProductsEdit}
      />
      <Resource options={{ allowedRoles: allRoles }} name="admin/messages" />
      <Resource options={{ allowedRoles: allRoles }} name="services/positions" />
      <Resource options={{ allowedRoles: allRoles }} name="services/mobile-operators" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/services/regions" />
      <Resource options={{ allowedRoles: allRoles }} name="services/brands" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/colors" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/services/frequencies" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/services/outlet-types" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/services/unexpected-rewards-levels" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/users/autocomplete" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/problems/2/problems/autocomplete" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/problems/3/problems/autocomplete" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/managers/autocomplete" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/knowledge-bank/articles/autocomplete" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/interactive-articles/types" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/legal-ids/autocomplete" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/cities/autocomplete" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/legal-entities/autocomplete" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/bonus-shop/category-types" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/bonus-shop/categories/physical" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/bonus-shop/categories/digital" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/event-mode/physical-products" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/event-mode/digital-products" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/bonus-shop/presents/types" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/reports/orders/types" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/reports/verifications/types" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/main-screen/components/types" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/main-screen/box-components/types" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/monthly-limitations/activity-types" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/reactions/types" />
      <Resource options={{ allowedRoles: allRoles }} name="admin/knowledge-bank/categories/sections" />
    </Admin>
  );
};

export default App;
