import React, { useState } from 'react';
import { Button, FormWithRedirect, required, SaveButton, TextInput, useNotify } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import IconCancel from '@material-ui/icons/Cancel';
import httpClient from '../../../core/httpClient';
import { availableMessageTemplates, serverApiUrl } from '../../../config/constants';

import styles from './SendToAllUsers.module.scss';
import { CustomDateTimeInput } from '../../CustomDateTimeInput';

const SendToAllUsersWithSchedule = ({ endpointUrl, record, recordKey }) => {
  const notify = useNotify();
  const [isOpen, setOpenStatus] = useState(false);
  const [isInnerOpen, setInnerOpenStatus] = useState(false);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setOpenStatus(true);
  };

  const handleCloseModal = () => {
    setOpenStatus(false);
  };

  const handleSendMessage = ({ message, caption }) => {
    const url = `${serverApiUrl}${endpointUrl}`;
    const body = JSON.stringify({ message, caption, [recordKey]: record.id });
    const options = { method: 'POST', body };

    httpClient(url, options)
      .then(() => handleCloseModal())
      .catch(() => notify("Error: Message wasn't sent", 'warning'));
  };

  const handleOpenInnerModal = (e) => {
    e.stopPropagation();
    setInnerOpenStatus(true);
  };

  const handleCloseInnerModal = () => {
    setInnerOpenStatus(false);
  };

  const handleSchedule = async ({ message, caption, schedule_date }) => {
    if (!message) {
      return notify('Field is missing', 'warning');
    }

    const url = `${serverApiUrl}/admin/schedule/article`;
    const body = JSON.stringify({
      message,
      caption,
      schedule_date: schedule_date ? new Date(schedule_date).toISOString() : null,
      [recordKey]: record.id,
      type: 'all',
    });
    const options = { method: 'POST', body };

    httpClient(url, options)
      .then(() => {
        notify('Message was scheduled', 'info');
        handleCloseModal();
      })
      .catch(() => notify("Error: Message wasn't scheduled", 'warning'));
  };

  return (
    <>
      <IconButton onClick={handleOpenModal}>
        <SendIcon />
      </IconButton>
      <Dialog open={isOpen} onClick={(e) => e.stopPropagation()}>
        <DialogTitle>Send to all users</DialogTitle>
        <FormWithRedirect
          save={handleSendMessage}
          render={({ handleSubmitWithRedirect, submitting, pristine, form }) => (
            <>
              <DialogContent className={styles.dialogContent}>
                <TextInput label="Caption" source="caption" />
                <TextInput
                  helperText={`Available replacements: ${availableMessageTemplates.join(', ')}`}
                  label="Message"
                  source="message"
                  fullWidth
                  validate={required()}
                />
              </DialogContent>
              <DialogActions>
                <>
                  <Button
                    label="Schedule"
                    type="button"
                    alignIcon="right"
                    size="medium"
                    variant="contained"
                    disabled={submitting || pristine || !form.getFieldState('message')?.value}
                    onClick={handleOpenInnerModal}
                  >
                    <SendIcon />
                  </Button>
                  <Dialog open={isInnerOpen} onClick={(e) => e.stopPropagation()}>
                    <>
                      <DialogContent>
                        <CustomDateTimeInput label="Select the schedule date" source="schedule_date" />
                      </DialogContent>
                      <DialogActions style={{ justifyContent: 'center' }}>
                        <Button label="ra.action.cancel" onClick={handleCloseInnerModal}>
                          <IconCancel />
                        </Button>

                        <Button
                          color="primary"
                          label="Schedule"
                          disabled={!form.getFieldState('schedule_date')?.value}
                          onClick={() =>
                            handleSchedule({
                              message: form.getFieldState('message')?.value,
                              caption: form.getFieldState('caption')?.value,
                              schedule_date: form.getFieldState('schedule_date')?.value,
                            })
                          }
                        >
                          <SendIcon />
                        </Button>
                      </DialogActions>
                    </>
                  </Dialog>
                </>
                <SaveButton
                  disabled={submitting || pristine || !form.getFieldState('message')?.value}
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                />
                <Button label="ra.action.cancel" onClick={handleCloseModal}>
                  <IconCancel />
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export default SendToAllUsersWithSchedule;
