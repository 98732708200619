export const APP_TYPES = {
  ALL: 'ALL',
  IRELAND: 'IRELAND',
  HUNGARY: 'HUNGARY',
  BELARUS: 'BELARUS',
  GEORGIA: 'GEORGIA',
  ARMENIA: 'ARMENIA',
  AZERBAIJAN: 'AZERBAIJAN',
  DEMO: 'DEMO',
};

export const APP_ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const CAUCASUS_TYPES = [APP_TYPES.GEORGIA, APP_TYPES.ARMENIA, APP_TYPES.AZERBAIJAN];

const isHungary = process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY;

export const ALL_WITHOUT_IRELAND = [
  APP_TYPES.BELARUS,
  APP_TYPES.HUNGARY,
  APP_TYPES.GEORGIA,
  APP_TYPES.ARMENIA,
  APP_TYPES.AZERBAIJAN,
  APP_TYPES.DEMO,
];

export const roles = {
  ADMIN: 'Administrator',
  BA: 'BA',
  JTI_TEAM: 'JTI Team',
  USER: 'User',
  RSM: 'RSM',
};

export const shopsByAppType = {
  [APP_TYPES.AZERBAIJAN]: [
    { id: 'Umico', name: 'Umico' },
    { id: 'KirbitTech', name: 'KirbitTech' },
  ],
  [APP_TYPES.GEORGIA]: [
    { id: 'UNICARD', name: 'UNICARD' },
    { id: 'OPPA', name: 'OPPA' },
  ],
};

export const managers_select = [
  { id: 'BA', name: 'BA' },
  { id: 'JTI Team', name: 'JTI Team' },
  { id: 'RSM', name: 'RSM' },
];

export const user_statuses = {
  'Not Active': 0,
  Active: 1,
  'Waiting moderation': 2,
  'Waiting Remove': 3,
  Blocked: 4,
  Limited: 5,
  TBC: 6,
  'Waiting for registration': 7,
};

export const STATISTICS_ALLOWED_ROLES = {
  [APP_TYPES.IRELAND]: [roles.ADMIN, roles.JTI_TEAM, roles.BA, roles.RSM],
  [APP_TYPES.HUNGARY]: [roles.ADMIN, roles.JTI_TEAM, roles.BA, roles.RSM],
  [APP_TYPES.GEORGIA]: [roles.ADMIN, roles.JTI_TEAM, roles.BA, roles.RSM],
  [APP_TYPES.ARMENIA]: [roles.ADMIN, roles.JTI_TEAM, roles.BA, roles.RSM],
  [APP_TYPES.AZERBAIJAN]: [roles.ADMIN, roles.JTI_TEAM, roles.BA, roles.RSM],
};

const API_TYPES = {
  [APP_TYPES.HUNGARY]: 'v1',
  [APP_TYPES.IRELAND]: 'v3',
  [APP_TYPES.BELARUS]: 'v3',
  [APP_TYPES.GEORGIA]: 'v3',
  [APP_TYPES.ARMENIA]: 'v3',
  [APP_TYPES.AZERBAIJAN]: 'v3',
  [APP_TYPES.DEMO]: 'v3',
};

export const timeZones = {
  [APP_TYPES.HUNGARY]: 'Europe/Budapest',
  [APP_TYPES.IRELAND]: 'Europe/Dublin',
  [APP_TYPES.BELARUS]: 'Europe/Minsk',
  [APP_TYPES.GEORGIA]: 'Asia/Tbilisi',
  [APP_TYPES.ARMENIA]: 'Asia/Yerevan',
  [APP_TYPES.AZERBAIJAN]: 'Asia/Baku',
  [APP_TYPES.DEMO]: 'Europe/Dublin',
};

export const ACCOUNT_CODE_LENGTH = {
  [APP_TYPES.HUNGARY]: 7,
  [APP_TYPES.IRELAND]: 7,
  [APP_TYPES.BELARUS]: 7,
  [APP_TYPES.GEORGIA]: 6,
  [APP_TYPES.ARMENIA]: [5, 6],
  [APP_TYPES.AZERBAIJAN]: 6,
};

export const serverApiUrl = `${process.env.REACT_APP_API_URL}/${API_TYPES[process.env.REACT_APP_APP_TYPE]}`;

export const allRoles = Object.values(roles);

export const statuses = [
  { id: 0, name: 'Deactivated' },
  { id: 1, name: 'Active', color: '#56D9A2' },
  { id: 2, name: 'Waiting moderation', color: '#FFCC66' },
  { id: 8, name: 'Blocked by admin', color: '#D9D9D9' },
  { id: 4, name: 'Blocked', color: '#808080' },
];

export const belarusAndCaucasusUserStatuses = {
  Deactivated: 0,
  Active: 1,
  'Waiting moderation': 2,
  Blocked: 4,
  'Waiting for registration': 7,
  'Blocked by admin': 8,
};

export const belarusUserInputStatuses = [
  { id: 0, name: 'Deactivated' },
  { id: 1, name: 'Active' },
  { id: 2, name: 'Waiting moderation', adminOnly: true },
  { id: 4, name: 'Blocked' },
  { id: 7, name: 'Waiting for registration', adminOnly: true },
  { id: 8, name: 'Blocked by admin', adminOnly: true },
];

export const caucasusUserInputStatuses = [
  { id: 0, name: 'Deactivated' },
  { id: 1, name: 'Active' },
  { id: 2, name: 'Waiting moderation', adminOnly: true },
  { id: 4, name: 'Blocked' },
  { id: 8, name: 'Blocked by admin', adminOnly: true },
];

export const CHANGE_USER_STATUS_TRIGGERS_BELARUS = {
  1: 'Унаследован от торгового объекта',
  2: 'Длительное нахождение на деактивированном торговом объекте',
  3: 'Чек-ин не был пройден в срок',
  4: 'Заблокирован администрацией JTI',
  5: 'Присвоен торговым представителем',
  6: 'Регистрация не завершена',
  7: 'Длительное время без регистрации',
  8: 'Пользователь деактивирован по собственному запросу',
};

export const CHANGE_USER_STATUS_TRIGGERS_GEORGIA = {
  1: 'გადმოტანილია სავაჭრო წერტილიდან',
  2: 'ხანგრძლივი პერიოდის მანძილზე არააქტიურის სტატუსის ქონა',
  4: 'დაბლოკილია JTI-ს ადმინისტრაციის მიერ',
  5: 'მინიჭებულია გაყიდვების წარმომადგენლის მიერ',
};

export const CHANGE_USER_STATUS_TRIGGERS_AZERBAIJAN = {
  1: 'Ticarət obyektindən miras qalmışdır',
  2: 'Deaktiv edilmiş ticarət obyektində uzun müddət qalmaq',
  4: 'JTI administratoru tərəfindən bloklanıb',
  5: 'Satış nümayəndəsi tərəfindən təyin edilir',
};

export const CHANGE_USER_STATUS_TRIGGERS_ARMENIA = {
  1: 'Ժառանգված է վաճառակետից',
  2: 'Երկար ժամանակ է, ինչ գտնվում է ապաակտիվացված վաճառակետում',
  4: 'Արգելափակվել է JTI ադմինիստրացիայի կողմից',
  5: 'Նշանակված է վաճառքի ներկայացուցչի կողմից',
};

export const caucasusInputStatuses = [
  { id: 0, name: 'Deactivated' },
  { id: 1, name: 'Active' },
  { id: 2, name: 'Waiting moderation', adminOnly: true },
  { id: 4, name: 'Blocked' },
  { id: 8, name: 'Blocked by admin', adminOnly: true },
];

export const irelandUsersStatuses = [
  { id: 1, name: 'Activate' },
  { id: 2, name: 'Waiting for moderation' },
  { id: 4, name: 'Blocked' },
];

export const hungaryUsersStatuses = [
  { id: 1, name: 'Activate' },
  { id: 2, name: 'Waiting for moderation' },
  { id: 4, name: 'Blocked' },
];

export const eSignatureStatuses = [
  { id: 0, name: 'Not requested' },
  { id: 1, name: 'Requested' },
  { id: 2, name: 'Completed' },
  { id: 3, name: 'Failed' },
  { id: 4, name: 'Expired' },
];

export const lepCodeStatuses = [
  { id: 0, name: 'In draw', color: '#FFCC66' },
  { id: 1, name: 'Won', color: '#45D67F' },
];

export const customStatuses = [
  ...(isHungary
    ? [{ id: 'Logged in', name: 'Logged in', color: '#56D9A2' }]
    : [{ id: 'Active users', name: 'Active users', color: '#56D9A2' }]),
  { id: 'Deleted users', name: 'Deleted users', color: '#D9D9D9' },
  { id: 'Live contacts', name: 'Live contacts', color: '#FF6666' },
  { id: 'Aged contacts', name: 'Aged contacts', color: '#FFCBCB' },
  { id: 'Cold contacts', name: 'Cold contacts', color: '#66A3FF' },
  { id: 'Waiting for moderation', name: 'Waiting for moderation', color: '#FFCC66' },
  { id: 'Blocked', name: 'Blocked', color: '#808080' },
];

export const outletContractedStatuses = [
  { id: true, name: 'root.contracted_statuses.contracted' },
  { id: false, name: 'root.contracted_statuses.not_contracted' },
];

export const outletStatuses = [
  { id: 1, name: 'ACCESS' },
  { id: 2, name: 'NO ACCESS' },
];

export const userVerificationStatuses = [
  { id: true, name: 'Verified' },
  { id: false, name: 'Not Verified' },
];

export const outletContractTypes = [
  { id: 'LIGHT', name: 'Light' },
  { id: 'STANDARD', name: 'Standard' },
  { id: 'ADVANCED', name: 'Advanced' },
];

export const booleanFilterChoices = [
  { id: false, name: 'No' },
  { id: true, name: 'Yes' },
];

export const ordersStatuses = [
  { id: 1, name: 'New' },
  { id: 2, name: 'Delivered' },
  { id: 3, name: 'Sent' },
  { id: 4, name: 'Rejected' },
];

export const chatStatuses = [
  { id: 0, name: 'All', value: 'all' },
  { id: 1, name: 'New', value: 'new' },
  { id: 2, name: 'Marked', value: 'marked' },
];

export const cpaProgramSelectStatuses = [
  { id: 1, name: 'Started', color: '#4CBF8F' },
  { id: 2, name: 'Moderated', color: '#FFAC30' },
  { id: 3, name: 'Finished', color: '#918d8d' },
];

export const productStatusSelectStatuses = [
  { id: 0, name: 'Empty' },
  { id: 1, name: 'New product' },
  { id: 2, name: 'Update' },
  { id: 3, name: 'Change name' },
];

export const unexpectedRewardsTypes = [
  { id: 1, name: 'Quizzes played' },
  { id: 5, name: 'Ordered items' },
  { id: 7, name: 'Lottery played' },
];

export const quizzesQuestionTypes = {
  commonText: 1,
  trueFalse: 2,
  picture: 3,
};

export const quizPackTypes = {
  normal: 1,
  special: 2,
  multibrand: 10005,
};

export const kbCategoryTypes = {
  Article: 1,
  Survey: 2,
  SalesTips: 3,
};

export const hardKbCategoryId = {
  News: 1,
};

export const knowledgeBankCategorySections = [
  { id: 1, name: 'Knowledge Bank' },
  { id: 2, name: 'Branding Ban' },
];

export const formTypeTogglerOptions = [
  { value: quizzesQuestionTypes.commonText, label: 'Common text question' },
  { value: quizzesQuestionTypes.trueFalse, label: 'True/False question' },
  { value: quizzesQuestionTypes.picture, label: 'Choose the correct picture question' },
];

export const mainScreenBoxComponentTypes = {
  quest: 1,
  lottery: 2,
  kb_article: 3,
  special_quiz: 4,
};

export const mainScreenBoxComponentTypeToggleOptions = [
  { value: mainScreenBoxComponentTypes.quest, label: 'Quest' },
  { value: mainScreenBoxComponentTypes.lottery, label: 'Chance' },
  { value: mainScreenBoxComponentTypes.kb_article, label: 'KB Article' },
  { value: mainScreenBoxComponentTypes.special_quiz, label: 'Special Quiz' },
];

export const mainScreenFocusComponentTypes = {
  solo_quiz: 1,
  quiz_with_user: 2,
  question_of_the_day: 3,
  kb_article: 4,
  special_quiz: 5,
  kb_game: 6,
  survey: 7,
  multibrand_quiz: 8,
  event_mode: 9,
  single_mode: 10,
  loyalty_program: 11,
  kb_category: 12,
  check_in: 13,
  invite_code: 14,
  achievements: 15,
  achievement: 16,
  lep: 17,
  pack_recognition: 18,
  lottery: 19,
};

export const mainScreenFocusComponentTypeToggleOptions = [
  { value: mainScreenFocusComponentTypes.solo_quiz, label: 'Solo Game' },
  { value: mainScreenFocusComponentTypes.quiz_with_user, label: 'Quiz with user' },
  { value: mainScreenFocusComponentTypes.question_of_the_day, label: 'Question of the Day' },
  { value: mainScreenFocusComponentTypes.kb_article, label: 'KB Article' },
  { value: mainScreenFocusComponentTypes.special_quiz, label: 'Special Quiz' },
  { value: mainScreenFocusComponentTypes.kb_game, label: 'KB Game' },
  { value: mainScreenFocusComponentTypes.survey, label: 'Survey' },
  { value: mainScreenFocusComponentTypes.multibrand_quiz, label: 'Multibrand Quiz' },
  { value: mainScreenFocusComponentTypes.event_mode, label: 'Event Mode' },
  { value: mainScreenFocusComponentTypes.single_mode, label: 'Single Mode' },
  { value: mainScreenFocusComponentTypes.loyalty_program, label: 'Loyalty program' },
  { value: mainScreenFocusComponentTypes.kb_category, label: 'KB Category' },
  { value: mainScreenFocusComponentTypes.check_in, label: 'Check In' },
  { value: mainScreenFocusComponentTypes.invite_code, label: 'Invite Code' },
  { value: mainScreenFocusComponentTypes.achievements, label: 'Achievements' },
  { value: mainScreenFocusComponentTypes.achievement, label: 'Achievement' },
  { value: mainScreenFocusComponentTypes.lep, label: 'LEP' },
  { value: mainScreenFocusComponentTypes.lottery, label: 'Chance' },
];

export const mainScreenItemComponentTypes = {
  kb_article: 1,
  special_quiz: 2,
  kb_game: 3,
  survey: 4,
  multibrand_quiz: 5,
};

export const mainScreenItemComponentTypeToggleOptions = [
  { value: mainScreenItemComponentTypes.kb_article, label: 'KB Article' },
  { value: mainScreenItemComponentTypes.special_quiz, label: 'Special Quiz' },
  { value: mainScreenItemComponentTypes.kb_game, label: 'KB Game' },
  { value: mainScreenItemComponentTypes.survey, label: 'Survey' },
  { value: mainScreenItemComponentTypes.multibrand_quiz, label: 'Multibrand Quiz' },
];

export const mainScreenModuleComponentTypes = {
  solo_quiz: 1,
  quiz_with_user: 2,
  question_of_the_day: 3,
  lottery: 4,
  special_quiz: 5,
  kb_article: 6,
  kb_game: 7,
  bonus_shop: 8,
  general_quiz: 9,
  event_mode: 10,
  single_mode: 11,
  kb_category: 12,
  survey: 13,
  loyalty_program: 14,
  check_in: 15,
  invite_code: 16,
  achievements: 17,
  achievement: 18,
  lep: 19,
  daily_game: 20,
  pack_recognition: 21,
  kb: 22,
  brand_information: 23,
  subscreen: 24,
};

export const mainScreenModuleComponentTypeToggleOptions = [
  { value: mainScreenModuleComponentTypes.solo_quiz, label: 'Solo Game' },
  { value: mainScreenModuleComponentTypes.quiz_with_user, label: 'Quiz with user' },
  { value: mainScreenModuleComponentTypes.question_of_the_day, label: 'Question of the Day' },
  { value: mainScreenModuleComponentTypes.lottery, label: 'Chance' },
  { value: mainScreenModuleComponentTypes.special_quiz, label: 'Special Quiz' },
  { value: mainScreenModuleComponentTypes.kb_article, label: 'KB Article' },
  { value: mainScreenModuleComponentTypes.kb_game, label: 'KB Game' },
  { value: mainScreenModuleComponentTypes.bonus_shop, label: 'Bonus Shop' },
  { value: mainScreenModuleComponentTypes.general_quiz, label: 'General Quizzes' },
  { value: mainScreenModuleComponentTypes.event_mode, label: 'Event Mode' },
  { value: mainScreenModuleComponentTypes.single_mode, label: 'Single Mode' },
  { value: mainScreenModuleComponentTypes.kb_category, label: 'KB Category' },
  { value: mainScreenModuleComponentTypes.survey, label: 'Survey' },
  { value: mainScreenModuleComponentTypes.loyalty_program, label: 'Loyalty program' },
  { value: mainScreenModuleComponentTypes.check_in, label: 'Check In' },
  { value: mainScreenModuleComponentTypes.invite_code, label: 'Invite Code' },
  { value: mainScreenModuleComponentTypes.achievements, label: 'Achievements' },
  { value: mainScreenModuleComponentTypes.achievement, label: 'Achievement' },
  { value: mainScreenModuleComponentTypes.lep, label: 'LEP' },
  { value: mainScreenModuleComponentTypes.daily_game, label: 'Daily Game' },
  { value: mainScreenModuleComponentTypes.pack_recognition, label: 'Pack Recognition' },
  { value: mainScreenModuleComponentTypes.kb, label: 'KB' },
  { value: mainScreenModuleComponentTypes.brand_information, label: 'Brand Information' },
  { value: mainScreenModuleComponentTypes.subscreen, label: 'Subscreen' },
];

export const mainScreenFadingBlockTypes = {
  quick_tip: 1,
  mystery_shopper: 2,
};

export const mainScreenFadingBlockComponentToggleOptions = [
  { value: mainScreenFadingBlockTypes.quick_tip, label: 'Quick tip' },
  { value: mainScreenFadingBlockTypes.mystery_shopper, label: 'Mystery Shopper' },
];

export const mainScreenSectionTypes = {
  box: 1,
  focus: 2,
  items: 3,
  modules: 4,
  fading_block: 5,
};

export const activityTypes = {
  kb_article: 1,
  kb_game: 2,
  special_quiz: 3,
  general_quiz: 4,
  quest: 5,
  question_of_the_day: 6,
  lottery: 7,
  promo_code: 8,
  single_mode: 9,
  multibrand_quiz: 10,
  sales_tips: 11,
};

export const mainScreenSectionTypeToggleOptions = [
  { value: mainScreenSectionTypes.box, label: 'Box component' },
  { value: mainScreenSectionTypes.focus, label: 'Focus component' },
  { value: mainScreenSectionTypes.items, label: 'Items component' },
  { value: mainScreenSectionTypes.modules, label: 'Modules component' },
  { value: mainScreenSectionTypes.fading_block, label: 'Fading block component' },
];

export const targetGroupAttributeTypes = {
  POSITION: 'position_id',
  TIER: 'tier_id',
  ACCOUNT_CODE: 'account_codes',
  CITY: 'city_id',
  MANAGER: 'manager_id',
  USER_ID: 'id',
  ROLE: 'role',
  OUTLET_CONTRACTED: 'outlet_contracted',
  OUTLET_CONTRACT_TYPE: 'outlet_contract_type',
  USER_VERIFICATION: 'verified',
  REGISTRATION: 'registration',
  REVERSED: 'group_reversed',
};

export const phoneCodes = {
  [APP_TYPES.HUNGARY]: '+36',
  [APP_TYPES.IRELAND]: '+353(0)',
  [APP_TYPES.BELARUS]: '+375',
  [APP_TYPES.GEORGIA]: '+995',
  [APP_TYPES.ARMENIA]: '+374',
  [APP_TYPES.AZERBAIJAN]: '+994',
  [APP_TYPES.DEMO]: '+353(0)',
};

export const usersDiagramsColorScheme = {
  'Active users': '#56D9A2',
  'Deleted users': '#D9D9D9',
  'Live contacts': '#FF6666',
  'Aged contacts': '#FFCBCB',
  'Cold contacts': '#66A3FF',
  'Waiting for moderation': '#FFCC66',
  Blocked: '#808080',
  'Logged in': '#BB74DC',
  'Downloaded users': '#FF6666',
  'Not downloaded users': '#66A3FF',
};

export const usersStatusesDiagramColorScheme = {
  Active: '#56D9A2',
  Blocked: '#6a87ff',
  'Blocked by Admin': '#ff0000',
  'Waiting for moderation': '#FFCC66',
  'Waiting Moderation': '#FFCC66',
  'Waiting Remove': '#FF6666',
  TBC: '#66A3FF',
  'Waiting for registration': '#612d7a',
  Limited: '#808080',
  Deactivated: '#808080',
};

export const assignedUsersDiagramColorScheme = {
  Assigned: '#56D9A2',
  Remaining: '#FF6666',
};

export const outletSellersStatsColorScheme = {
  'With Sellers': '#56D9A2',
  'Without Sellers': '#FF6666',
};

export const outletStatusesDiagramColorScheme = {
  ACCESS: '#6a87ff',
  'NO ACCESS': '#808080',
};

export const linearDiagramFields = {
  loggedIn: 'Logged in',
  activeUsers: 'Active users',
  deletedUsers: 'Deleted users',
  liveContacts: 'Live contacts',
  agedContacts: 'Aged contacts',
  coldContacts: 'Cold contacts',
  waitingContacts: 'Waiting for moderation',
  blockedContacts: 'Blocked',
};

export const linearDiagramPeriodOptions = [
  { id: 'ALL', name: 'root.statistics.all_period' },
  { id: 'LAST_FOUR_WEEKS', name: 'root.statistics.last_four_weeks' },
  { id: 'BEFORE_LAST_FOUR_WEEKS', name: 'root.statistics.before_last_four_weeks' },
];

export const linearDiagramRegionFilterOptionTypes = {
  REGION: 'REGION',
  MANAGER: 'MANAGER',
};

export const linearDiagramDefaultRegion = {
  id: 'ALL',
  name: 'root.statistics.national',
};

export const loyaltyAttachmentStatuses = [
  { id: 0, name: 'Failed (MANUAL)' },
  { id: 1, name: 'Accepted (MANUAL)' },
  { id: 2, name: 'Accepted (AUTO)' },
  { id: 3, name: 'Sent' },
  { id: 4, name: 'Sent to manual' },
];

export const genderIdentity = [
  { id: 'Male', name: 'Male' },
  { id: 'Female', name: 'Female' },
  { id: 'Decline to answer', name: 'Decline to answer' },
];

export const passportStatuses = [
  { id: 'NOT_VERIFIED', name: 'Нет данных' },
  { id: 'MODERATION', name: 'На модерации' },
  { id: 'VERIFIED', name: 'Данные подтверждены' },
];

export const appTypesStatuses = {
  [APP_TYPES.HUNGARY]: hungaryUsersStatuses,
  [APP_TYPES.IRELAND]: statuses,
  [APP_TYPES.BELARUS]: belarusUserInputStatuses,
  [APP_TYPES.GEORGIA]: caucasusUserInputStatuses,
  [APP_TYPES.ARMENIA]: caucasusUserInputStatuses,
  [APP_TYPES.AZERBAIJAN]: caucasusUserInputStatuses,
  [APP_TYPES.DEMO]: statuses,
};

export const bonusShopCategoryTypes = {
  DIGITAL: 1,
  PHYSICAL: 2,
  TRANSFER: 3,
  BOOSTER: 4,
  UMICO: 5,
  OPPA: 6,
};

export const ROOT_LEVEL = 1;

export const PROBLEM_LEVELS = [ROOT_LEVEL, 2, 3];

export const ticketStatuses = {
  CHECKING: 0,
  COMPLETED: 1,
  NO_ANSWER: 2,
};

export const CPA_PROGRAM_STATUSES = {
  STARTED: 1,
  MODERATED: 2,
  FINISHED: 3,
};

export const ticketStatusesForComponent = [
  { id: ticketStatuses.CHECKING, name: 'Checking' },
  { id: ticketStatuses.COMPLETED, name: 'Completed' },
  { id: ticketStatuses.NO_ANSWER, name: 'No Answer' },
];

export const interactiveTypes = [
  { id: 0, name: 'manual' },
  { id: 1, name: 'checkbox' },
  { id: 2, name: 'radiobutton' },
  { id: 3, name: 'dropdown' },
];

export const availableLanguages = {
  en: 'English',
  hu: 'Hungarian',
  ru: 'Russian',
};

export const quickTipsQuestionTypes = {
  priceQuestion: 1,
  quizQuestion: 2,
};

export const quickTipsQuestionTypeSelectOptions = [
  { value: quickTipsQuestionTypes.priceQuestion, label: 'Price question' },
  { value: quickTipsQuestionTypes.quizQuestion, label: 'Quiz question' },
];

export const availableMessageTemplates = ['name'].map((t) => `[${t}]`);

export const outletsTMRCoverageOptions = [
  { id: 'yes', name: 'Yes' },
  { id: 'no', name: 'No' },
];

export const outletsActivityStatusesOptions = [
  { id: 1, name: 'Access' },
  { id: 2, name: 'No Access' },
];

export const s3FilePaths = {
  // private
  chat: 'chat/admin/',
  images: 'images/',
  files: 'files/',
  userImage: 'profile/user/',
  managerImage: 'profile/manager/',
  pdfCoupons: 'bonus-shop/pdf/',
  xlsxCoupons: 'bonus-shop/xlsx/',
  idCard: 'id-card/user/',

  // public
  progressBar: 'profile/progress-bar/',
  achievements: 'knowledge-bank/achievements/',
  achievementTasks: 'knowledge-bank/achievements/tasks/',
  articles: 'knowledge-bank/articles/',
  salesTips: 'knowledge-bank/sales-tips/',
  brand: 'knowledge-bank/brands/',
  recognition: 'recognition/admin/',
  allUploads: 'main-screen/all-uploads/',
  quickTips: 'main-screen/quick-tips/',
  salesSimulator: 'main-screen/sales-simulator/',
  quiz: 'main-screen/quiz/',
  bonusShop: 'bonus-shop/',
  rewards: 'bonus-shop/rewards/',
  profile: 'profile/',
  mysteryShopper: 'main-screen/mystery-shopper/',
  popupImage: 'popup/image/',
  popupLogo: 'popup/logo/',
  figureGame: 'figure-game/',
  favoriteTiles: 'favorite-tiles/',
};

export const azeUserIDCardStatusOptions = [
  { id: 1, name: 'No Data' },
  { id: 2, name: 'On Moderation' },
  { id: 3, name: 'Data Confirmed' },
  { id: 4, name: 'Moderation Failed: Duplicate Card' },
  { id: 5, name: 'Moderation Failed: Incorrect personal data' },
  { id: 6, name: 'Moderation Failed: Not AZE citizen ' },
  { id: 7, name: 'Moderation Failed: Wrong image' },
  { id: 8, name: 'Moderation Failed: Other reason' },
];
export const geoUserIDCardStatusOptions = [
  { id: 1, name: 'No Data' },
  { id: 2, name: 'On Moderation' },
  { id: 3, name: 'Data Confirmed' },
  { id: 4, name: 'Moderation Failed: Duplicate Card' },
  { id: 5, name: 'Moderation Failed: Incorrect personal data' },
  { id: 6, name: 'Moderation Failed: Not GEO citizen ' },
  { id: 7, name: 'Moderation Failed: Wrong image' },
  { id: 8, name: 'Moderation Failed: Other reason' },
];

export const tradeProgramsPhasesOptions = [
  { id: 1, name: 'Data collection' },
  { id: 2, name: 'Assessment' },
  { id: 3, name: 'Ended' },
  { id: 4, name: 'Paused' },
];

export const tradeProgramAddressesStatusOptions = [
  { id: 1, name: 'Processing', color: '#FFAC30' },
  { id: 2, name: 'Confirmed', color: '#4CBF8F' },
  { id: 3, name: 'Denied', color: '#ff0000' },
];

export const tradeProgramAddressesStatus = {
  Processing: 1,
  Confirmed: 2,
  Denied: 3,
};

export const tradeProgramPhases = {
  DataCollection: 1,
  Assessment: 2,
  Ended: 3,
  Paused: 4,
};

export const baBonusStatus = {
  inactive: 0,
  active: 1,
};

export const baBonusStatusSelectOptions = [
  { value: baBonusStatus.inactive, label: 'Inactive' },
  { value: baBonusStatus.active, label: 'Active' },
];

export const baBonusType = {
  moderateUsers: 0,
  activeUsers: 1,
};

export const baBonusTypeSelectOptions = [
  { value: baBonusType.moderateUsers, label: 'Moderate rate' },
  { value: baBonusType.activeUsers, label: 'Active users' },
];

export const CATEGORY_TYPES = {
  DEFAULT: 1,
  SALES_TIPS: 3,
  SALES_SIMULATORS: 4,
};

export const popupNavigationTypes = {
  CLOSE: 'CLOSE',
  URL: 'URL',
  RELATIVE: 'RELATIVE',
};

export const popupApplicationTypes = [
  { id: 'SOLO_GAME', name: 'Solo Game', needID: true, contentIDLabel: 'KnowledgeBank Brand ID' },
  { id: 'SPECIAL_QUIZ', name: 'Special Quiz', needID: true, contentIDLabel: 'Special Quiz ID' },
  { id: 'BONUS_SHOP', name: 'Bonus Shop', needID: false, contentIDLabel: '' },
  { id: 'SURVEY', name: 'Survey', needID: true, contentIDLabel: 'KnowledgeBank Survey ID' },
  { id: 'DAILY_GAME', name: 'Daily Game', needID: false, contentIDLabel: '' },
  { id: 'MINI_GAME', name: 'Mini Game', needID: true, contentIDLabel: 'KnowledgeBank Game ID' },
  { id: 'PLOOM_MINI_GAME', name: 'Ploom Mini Game', needID: true, contentIDLabel: 'Ploom Game ID' },
  { id: 'BRAND_INFORMATION', name: 'Brand Information', needID: true, contentIDLabel: 'KnowledgeBank Brand ID' },
  { id: 'NEWS', name: 'News', needID: true, contentIDLabel: 'KnowledgeBank Article ID' },
  { id: 'PLOOM_NEWS', name: 'Ploom News', needID: true, contentIDLabel: 'Ploom News ID' },
  { id: 'PLOOM_QUIZ', name: 'Ploom Quiz', needID: true, contentIDLabel: 'Ploom Special Quiz ID' },
];

export const popupNavigationButtonTypesSelect = [
  { id: popupNavigationTypes.CLOSE, name: 'Close popup' },
  { id: popupNavigationTypes.URL, name: 'Web link' },
  { id: popupNavigationTypes.RELATIVE, name: 'Application' },
];

export const popupThemes = [
  { id: 'LIGHT', name: 'Light' },
  { id: 'DARK', name: 'Dark' },
];

export const popupCharacterLimits = {
  TITLE: 40,
  MESSAGE_TEXT: 300,
  BUTTON_TEXT: 25,
};
