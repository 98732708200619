import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';
import { isHungary } from '../../../../helpers/appTypeHelper';
import { knowledgeBankCategorySections } from '../../../../config/constants';

const getSectionName = (section_in_category) =>
  knowledgeBankCategorySections.find((section) => section_in_category === section.id).name;

const getCategoriesWithSectionName = (categories) =>
  categories.map((category) => {
    const section_name = getSectionName(category.section);
    const category_name = `(${section_name}) ${category.name}`;
    return {
      id: category.id,
      name: category_name,
    };
  });

const CustomSelectInput = (props) => {
  const { choices: categories, source } = props;
  let categories_with_section_name = [];

  if (categories) {
    categories.sort((a, b) => a.section - b.section);
    categories_with_section_name = getCategoriesWithSectionName(categories);
  }
  return (
    <>
      <SelectInput choices={categories_with_section_name} source={source} />
    </>
  );
};

const ArticlesFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      perPage={100}
      label="Category"
      source="category_id"
      reference="admin/knowledge-bank/categories"
      allowEmpty={false}
      alwaysOn
    >
      {isHungary() ? <CustomSelectInput /> : <SelectInput optionText="name" />}
    </ReferenceInput>
  </Filter>
);

export default ArticlesFilter;
