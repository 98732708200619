import React from 'react';
import { Pagination } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listPagination: {},
});

const ScheduleListPagination = (props) => {
  const classes = useStyles();

  return <Pagination rowsPerPageOptions={[25]} className={`${classes.listPagination}`} {...props} />;
};

export default ScheduleListPagination;
