import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, SimpleForm, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import UploadFileInput from '../../../../commonComponents/UploadInputs/UploadFileInput';
import { couponFormStyles } from './styles';
import CouponEditToolbar from './CouponEditToolbar';

const useStyles = makeStyles(couponFormStyles);

const CouponEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit title="Edit Coupon" {...props}>
      <SimpleForm toolbar={<CouponEditToolbar />}>
        <TextInput label="ID" source="id" disabled />
        <TextInput label="PDF link" source="pdf_link" />
        <UploadFileInput fileInputSource="pdf_link" buttonText="Upload PDF file" />
        <CustomDateInput label="Expired at" source="expired_at" />
        <ReferenceInput
          label="Digital product"
          source="product_id"
          reference="admin/bonus-shop/products"
          className={classes.productInput}
        >
          <SelectInput optionText="description" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default CouponEdit;
