/* eslint-disable */
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button } from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import Description from '@material-ui/icons/Description';
import IconDelete from '@material-ui/icons/Delete';

import React, { useState } from 'react';
import { Datagrid, List, FunctionField, useRefresh } from 'react-admin';
import ScheduleListPagination from './ScheduleListPagination';
import { CustomDateField } from '../../commonComponents/DateField';
import { serverApiUrl } from '../../config/constants';
import httpClient from '../../core/httpClient';

const ScheduleList = (props) => (
  <List
    title="Schedule list"
    bulkActionButtons={false}
    sort={{ field: 'created_at', order: 'DESC' }}
    pagination={<ScheduleListPagination />}
    {...props}
  >
    <Datagrid>
      <CustomDateField label="Schedule Date" source="date" sortable={false} />
      <FunctionField label="Type" render={(render) => <ScheduleType schedule={render} />} />
      <FunctionField label="Published" render={(render) => (render.published ? 'Yes' : 'No')} />
      <FunctionField label="Data" render={(render) => <MoreInfo schedule={render} />} />
      <FunctionField label="Delete" render={(render) => <DeleteSchedule schedule={render} />} />
    </Datagrid>
  </List>
);

const ScheduleType = ({ schedule }) => {
  const type = schedule.schedule_type;

  if (type === 'message') {
    return <div>Send message</div>;
  }

  if (type === 'article') {
    return (
      <div>
        Send article (
        {schedule?.meta?.type === 'all' ? 'All users' : schedule?.meta?.type === 'single' ? 'Single' : 'Group'})
      </div>
    );
  }

  if (type === 'coupon' || type === 'product' || type === 'present') {
    return (
      <div>
        {schedule?.meta?.type === 'create' ? 'Create' : schedule?.meta?.type === 'update' ? 'Update' : ''} {type}
      </div>
    );
  }

  return <div></div>;
};

const MoreInfo = ({ schedule }) => {
  // const type = schedule.schedule_type;
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleOpenModal}>
        <Description />
      </IconButton>
      <Dialog open={isOpen} onClick={(e) => e.stopPropagation()}>
        <DialogContent>
          <div>
            <pre>{JSON.stringify(schedule?.body, null, 2)}</pre>
          </div>
        </DialogContent>

        <DialogActions>
          <Button label="Close" onClick={handleCloseModal}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DeleteSchedule = ({ schedule }) => {
  const [isOpen, setIsOpen] = useState(false);
  const refresh = useRefresh();

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const deleteSchedule = () => {
    const url = `${serverApiUrl}/admin/schedule`;
    const body = JSON.stringify({ id: schedule.id });
    const options = { method: 'DELETE', body };

    httpClient(url, options)
      .then(() => {
        refresh();
        handleCloseModal();
      })
      .catch(() => notify('Error: Failed to delete schedule', 'warning'));
  };

  return (
    <div>
      <IconButton color="secondary" onClick={handleOpenModal}>
        <IconDelete />
      </IconButton>
      <Dialog open={isOpen} onClick={(e) => e.stopPropagation()}>
        <DialogContent>
          <DialogTitle>Are you sure you want to delete this schedule?</DialogTitle>
        </DialogContent>

        <DialogActions>
          {/* <SaveButton disabled={submitting || pristine} handleSubmitWithRedirect={handleSubmitWithRedirect} /> */}
          <Button label="ra.action.cancel" onClick={handleCloseModal}>
            <IconCancel style={{ marginRight: '5px' }} /> Cancel
          </Button>

          <Button color="secondary" label="Delete" onClick={deleteSchedule}>
            <IconDelete style={{ marginRight: '3px' }} /> Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ScheduleList;
