import React from 'react';
import { Edit, NumberInput, required, SimpleForm } from 'react-admin';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../config/constants';

const FavoriteTileEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <NumberInput label="Position" source="position" min={1} validate={required()} />
      <ImageInput label="Image" imageInputSource="image" imagePath={s3FilePaths.favoriteTiles} />
    </SimpleForm>
  </Edit>
);

export default FavoriteTileEdit;
