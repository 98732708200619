import React from 'react';
import { Pagination } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listPagination: {
    '& > div': {
      width: '25%',
    },
  },
});

const ChatListPagination = (props) => {
  const classes = useStyles();

  return <Pagination rowsPerPageOptions={[25, 50]} className={`${classes.listPagination}`} {...props} />;
};

export default ChatListPagination;
